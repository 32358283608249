import { Splide } from '@splidejs/splide';
import '@splidejs/splide/css/core';

class VisualSlide {
  constructor(el) {
    this.dom = {el: el};
    this.dom.sliderImage = this.dom.el.querySelector('[data-js="slider-image"]');
    this.dom.sliderText = this.dom.el.querySelector('[data-js="slider-text"]');

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.sliderImage = null;
    this.sliderText = null;

    this.optionImage = {
      autoplay: true,
      type: 'fade',
      rewind: true,
      // perPage: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      interval: 6000,
      speed: 2400,
      drag: false,
      arrows: false,
      pagination: false,
      isNavigation: true,
      // padding: `${160 / 1440 * 100}%`,
    };
    this.optionText = {
      // autoplay: true,
      type: 'fade',
      rewind: true,
      // perPage: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      interval: 6000,
      speed: 10,
      drag: false,
      arrows: false,
      pagination: true,
      // padding: `${160 / 1440 * 100}%`,
    };

    // this.option.easing = 'cubic-bezier(0.37, 0, 0.63, 1)';

    this.init();
  }

  init() {
    this.sliderImage = new Splide(this.dom.sliderImage, this.optionImage);
    this.sliderText = new Splide(this.dom.sliderText, this.optionText);
    this.sliderText.sync( this.sliderImage );
    this.sliderText.mount();
    this.sliderImage.mount();
    // this.slider.on('mounted', ()=>{
    //   this.mountedEvent(this.slider);
    // });
    // this.slider.on('move', (newIndex, oldIndex, destIndex)=>{
    //   this.moveEvent(newIndex + 1);
    // });
    // this.slider.on('moved', (newIndex, oldIndex, destIndex)=>{
    //   this.movedEvent();
    // });

    // window.addEventListener('resize', this.resizeEvent);

    
    // ブレイクポイントの瞬間に発火
    // this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    // this.checkBreakPoint(this.mql);
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');

      return;
    } else {
      // console.log('pc');

      return;
		}
	}
}

export default VisualSlide